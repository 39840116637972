import React from "react";
import { GlobalDataContext } from "../../context/context";


const TransparentHeader = ({ headertitle, bgimg, Subheader, svgClass }) => {

  const { rpdata } = React.useContext(GlobalDataContext);
  return (

    <div
      className={`w-full md:h-[85vh] h-[70vh] ${svgClass ? svgClass : 'svgHexagonInverso'} bg-cover bg-center bg-no-repeat relative before:absolute before:bg-[#00000091] before:w-full before:h-full`}
      style={{
        backgroundImage: `url("${bgimg}")`,
        backgroundAttachment: 'fixed'
      }}
    >
      <div className="flex flex-col  h-full pb-20 pt-0  md:pb-0 justify-end md:justify-center items-center relative">
        <div className="flex justify-center items-center">
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="logo"
            className="w-[90%] md:w-[50%]"
          />
        </div>
        <h1 className="text-white lg:mt-28 text-[35px] text-center lg:text-[50px]">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
